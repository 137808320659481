import React from "react";

function MobileBannerCmp({ BannerCmp }) {
  console.log(BannerCmp);
  return (
    <div className="mt-10 mb-5">
      <div className=" ml-20 mb-3 inline-block p-1 text-center">
        <h2 className="w-max text-xl bold tracking-wide font-bold  uppercase ">
          {BannerCmp.mobileName}
        </h2>
        <h2 className="ml-1 text-sm">
          by <b>{BannerCmp.mobile}</b>
        </h2>
      </div>
      <div className=" relative bg-lime-200 max-h-sm rounded-3xl flex justify-between gap-5 flex-wrap md:flex-nowrap p-6">
        <div className="md:absolute md:-top-20 md:right-12 z-10 flex justify-center items-center p-4 rounded-3xl border-4 border-white bg-lime-300">
          <img
            src={BannerCmp.img}
            alt=""
            className="h-60 w-52 md:h-80 md:w-72"
          />
        </div>
        <div className="w-full md:ml-20 flex flex-col gap-5">
          <h2 className="text-3xl font-extrabold md:text-5xl">
            {BannerCmp.modelName}
          </h2>
          <h2 className="text-2xl font-bold">45W SuperVOOC charging and 5000 mAh Battery</h2>
          <h2 className="text-lg"><del>₹ 17,999</del> <b> ₹ 12,999</b></h2>
          <h2 className="text-lg">Effective price:₹ 11,749 <sup>*</sup> </h2>
          <h2><sup>* including band & coupon offer</sup></h2>
        </div>
      </div>
    </div>
  );
}

export default MobileBannerCmp;

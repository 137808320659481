import React from "react";
import BannerSlider from "../components/BannerSlider";

function Baby_product() {
  const banner = [
    "/assets/mobile/mobileBanner1.png",
    "/assets/mobile/mobileBanner2.webp",
    "/assets/mobile/mobileBanner3.webp",
    "/assets/mobile/mobileBanner4.webp",
    "/assets/mobile/mobileBanner5.webp",
  ];
  return (
    <div className="w-full">
      <BannerSlider banner={banner} />
    </div>
  );
}

export default Baby_product;



import * as TYPES from './types'

export const sigupAction =(payload,callBack)=>(
    {
        type: TYPES["SIGNUP"],payload,callBack
    }
)
export const loginAction = (payload, callBack) => (
    {
    type: TYPES['LOGIN'], payload, callBack
})



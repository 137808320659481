import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";
import Productlist3 from "../components/Productlist3";

function Sports() {
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg",
  ];

  const sports = [
    "/assets/sports/sports1.png",
    "/assets/sports/sports2.png",
    "/assets/sports/sports3.png",
    "/assets/sports/sports4.png",
    "/assets/sports/sports6.png",
  ];
  const sports2 = [
    "/assets/sports/sportsBanner9.png",
    "/assets/sports/sportsBanner2.png",
    "/assets/sports/sportsBanner3.png",
    "/assets/sports/sportsBanner4.png",
    "/assets/sports/sportsBanner5.png",
    "/assets/sports/sportsBanner6.png",
    "/assets/sports/sportsBanner7.png",
    "/assets/sports/sportsBanner4.png",
    "/assets/sports/sportsBanner5.png",
    "/assets/sports/sportsBanner6.png",
    "/assets/sports/sportsBanner7.png",
    "/assets/sports/sportsBanner8.png",
  ];
  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white my-7">
        <div>Cricket</div>
        <div>Badminton</div>
        <div>Table Tennis</div>
        <div>Football</div>
        <div>Sports Wear</div>
        <div>Swimming</div>
        <div>Basketball</div>
        <div>Hockey</div>
        <div>Carom</div>
        <div>poll</div>
      </div>
      <div className="p-2 py-4 md:p-6 my-7 bg-white">
        <div className=" mb-10">
          <Productlist3 product={sports} />
        </div>
        <div className="mb-7">
          <Productlist3 product={sports2} />
        </div>
      </div>
    </div>
    
  );
}

export default Sports;

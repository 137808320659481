const imageToBase64 = async (image) => {
    if (!(image instanceof Blob)) {
      throw new TypeError("The provided value is not a Blob or File.");
    }
  
    const reader = new FileReader();
    reader.readAsDataURL(image);
  
    const data = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  
    return data;
  };
  
  export default imageToBase64;
  
import React, { useState } from "react";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { MdOutlineVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function NewPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const navigate=useNavigate();

  function SubmitNewPasswordData(){
    navigate("/login")
  }
  return (
    <section className="section">
      <div className="container mx-auto px-4 h-full flex justify-center items-center">
        <div className="w-full md:w-1/2 p-6 flex justify-center items-center flex-col">
          <form action="" className="mt-5 w-full md:max-w-sm">
            {/* {Email } */}
            <div className="flex flex-col mb-4">
              <label htmlFor="email" className="text-sm">
                Email <sup className="text-red-500">*</sup>
              </label>
              <input
                type="email"
                placeholder="Enter your email address"
                id="email"
                className="bg-slate-200 p-2 outline-none rounded-md text-sm"
              />
            </div>

            {/* {Password} */}
            <div className="flex flex-col mb-4">
              <label htmlFor="password" className="text-sm">
               New Password <sup className="text-red-500">*</sup>
              </label>
              <div className="w-full bg-slate-200 flex rounded-md overflow-hidden">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  id="password"
                  className="bg-slate-200 p-2 outline-none rounded-md w-full text-sm"
                />
                <p
                  className="cursor-pointer w-8 h-9 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineVisibility className="text-lg" />
                  ) : (
                    <MdOutlineVisibilityOff className="text-lg" />
                  )}
                </p>
              </div>
            </div>


            {/* button login  */}
            <div className="w-full border rounded-md mb-4 overflow-hidden">
              <button onClick={SubmitNewPasswordData} className="w-full py-[7px] text-sm text-center bg-emerald-800 text-white hover:bg-emerald-900 duration-300">
                
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="hidden md:flex w-1/2  items-center">
          <img
            src="https://img.freepik.com/premium-photo/guardian-digital-realm-mans-vigilance-login-gate_1134661-21588.jpg?w=740"
            alt="login Image"
            className="w-full lg:max-w-md object-cover"
          />
        </div>
      </div>
    </section>
  );
}

export default NewPassword;

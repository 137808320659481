"use client"
import { Provider } from "react-redux";
import { ReactNode } from "react";
import store from "./store";


// console.log("ReduxProvider")
export function ReduxProvider({ children }) {
    return <Provider store={store}>
        {children}
    </Provider>
}
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import OtpVerify from "../pages/OtpVerify";
import NewPassword from "../pages/NewPassword";
import Signup from "../pages/Signup";
import Mobile from "../pages/Mobile"
import Electronics from "../pages/Electronics"
import Mens_fashion from "../pages/Mens_fashion"
import Women_fashion from "../pages/Women_fashion"
import Home_furnishing from "../pages/Home_furnishing"
import VideoGame from "../pages/VideoGame"
import Kids_fashion from "../pages/Kids_fashion"
import Books from "../pages/Books"
import Sports from "../pages/Sports"
import Baby_product from "../pages/Baby_product"
import Toys from "../pages/Toys"
import SingleProduct from "../pages/SingleProduct";
import Contact from "../pages/Contact";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children:[
            {
                path:"",
                element:<Home />
            },
            {
                path:"/login",
                element:<Login />
            },
            {
                path:"/forget_password",
                element:<ForgetPassword />
            },
            {
                path:"/otp_verify",
                element:<OtpVerify />
            },
            {
                path:"/new_password",
                element:<NewPassword />
            },
            {
                path:"/sign_up",
                element:<Signup />
            },
            {
                path:"/mobile",
                element:<Mobile />
            },
            {
                path:"/electronics",
                element:<Electronics />
            },
            {
                path:"/mens_fashion",
                element:<Mens_fashion />
            },
            {
                path:"/women_fashion",
                element:<Women_fashion />
            },
            {
                path:"/home_furnishing",
                element:<Home_furnishing />
            },
            {
                path:"/video-games",
                element:<VideoGame />
            },
            {
                path:"/kids_fashion",
                element:<Kids_fashion />
            },
            {
                path:"/books",
                element:<Books />
            },
            {
                path:"/sports",
                element:<Sports />
            },
            {
                path:"/baby_product",
                element:<Baby_product />
            },
            {
                path:"/toys",
                element:<Toys />
            },
            {
                path:"/product/:brand/:id",
                element:<SingleProduct />
            },
            {
                path:"/contact",
                element:<Contact />
            }
    ]
  },
]);


export default router;
import React from "react";
import appleLogo from "../assets/apple.png"
import playStore from "../assets/playstore.png"
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-slate-800 mt-5 md:mt-0">
      <div className="container mx-auto px-4 py-6">
        <div className="w-full flex justify-between items-center flex-wrap">
          <div className="w-1/2 md:w-1/4 text-slate-200 font-bold text-center text-md md:text-xl">
            Get to know Us
          </div>
          <div className="w-1/2 md:w-1/4 text-slate-200 font-bold text-center text-md md:text-xl">
            Connect with Us
          </div>
          <div className="w-1/2 md:w-1/4 text-slate-200 font-bold text-center text-md md:text-xl">
            Make Money with Us
          </div>
          <div className="w-1/2 md:w-1/4 text-slate-200 font-bold text-center text-md md:text-xl">
            Let Us Help You
          </div>
        </div>

        <div className="mt-5 flex justify-center items-center flex-wrap gap-4">
          <div className="py-1 border bg-slate-200 rounded-full w-[200px] flex justify-center items-center gap-2">
            <div className="w-10 h-10">
              <img src={appleLogo} alt="" />
            </div>
            <div>
              <p className="text-xs">Download on the</p>
              <p className="font-bold -mt-1">App Store</p>
            </div>
          </div>
          <div className="py-1 border bg-slate-200 rounded-full w-[200px] flex justify-center items-center gap-2">
            <div className="w-10 h-10">
              <img src={playStore} alt="" />
            </div>
            <div>
              <p className="text-xs">Get it on</p>
              <p className="font-bold -mt-1">Google Play</p>
            </div>
          </div>
        </div>
        <div className="bg-slate-200 rounded-md text-black h-10 w-full mt-5 flex justify-center items-center font-bold">
          <p className="text-center text-sm md:text-md">Copyright &#169; 2024 | powered by <Link to="https://markletechandmedia.com/">markletechandmedia.com</Link>	</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";

function BestSeller({ props }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 md:flex md:flex-wrap md:justify-evenly">
      {props.map((item, index) => {
        return (
          <div
            key={index}
            className="w-full md:w-40 flex justify-center items-center"
          >
            <img
              src={item}
              alt={`Best Seller ${index}`}
              className="w-40 h-40 object-cover"
            />
          </div>
        );
      })}
    </div>
  );
}

export default BestSeller;

import { END_POINTS } from "../../utils/config.js";
import { call, takeLatest } from "redux-saga/effects";
import {SIGNUP,LOGIN,LOGOUT} from "../actions/types";
import axios from "axios"

function* signup(payload) {
  try {
    const response = yield axios.post(END_POINTS.SIGNUP, payload, {
      withCredentials: true
    });
    return response.data;  // Return response data on success
  } catch (error) {
    // Handle the error, maybe log it or show a user-friendly message
    console.error('Signup failed:', error.response?.data || error.message);
    throw error;  // Re-throw the error if needed
  }
}


export function* signupSaga(action){
  console.log(action.payload, "from auth saga ===================")
  try {
    const response = yield call(signup, action.payload);

    action.callBack(response);
  } catch (error) {
    console.error("Signup error", error);
    action.callBack(error);
  }
}


export function* authSaga(){
  yield takeLatest(SIGNUP, signupSaga);
}

export default authSaga;

import React from "react";
import category from "../assets/category.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

function CategorySlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    // autoplay: true, // Enable autoplay
    // autoplaySpeed: 2000,  // Set autoplay speed to 1 second
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

//   console.log("Category data: ", category);

  return (
    <div className="px-8 container mx-auto section py-4">
      <Slider {...settings}>
        {category.map((item, index) => (
          <Link to={item.path} key={index} className="text-black rounded-2xl">
            <div className="flex justify-center items-center">
              <img
                src={item.img}
                alt={item.name}
                className="w-10 h-10 md:h-16 md:w-16 rounded-full mix-blend-color-burn"
              />
            </div>
            <p className="text-center text-sm">{item.name}</p>
          </Link>
        ))}
      </Slider>
    </div>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow next-arrow`} onClick={onClick} />
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev-arrow`} onClick={onClick} />
  );
}

export default CategorySlider;

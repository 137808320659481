import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BannerSlider({ banner }) {
  // console.log(bannerImg)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    arrows: false, // Disable arrow buttons
  };

  return (
    <div className="my-3">
      <Slider {...settings}>
        {banner.map((item, index) => {
          return (
            <div className="w-full" key={index}>
              <img
                src={item}
                alt="Banner Image"
                className="w-full h-32 md:h-[270px] object-cover"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default BannerSlider;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import mobile from "../assets/mobile.json";
import { FaStar } from "react-icons/fa6";
import { FaRegStarHalfStroke } from "react-icons/fa6";
import { MdLocalOffer } from "react-icons/md";

function SingleProduct() {
  const { brand, id } = useParams();
  // const [count,setCount]=useState(1)
  const id2 = parseInt(id);
  const data = mobile.find((item, index) => {
    return item.id === 2;
  });
  // console.log(data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-2 py-4 md:p-6 bg-white my-5">
      <div className="w-full flex justify-between gap-16 flex-wrap bg-trnasparent md:flex-nowrap py-6">
        <div className="w-full md:w-1/2 bg-transparent flex flex-wrap md:flex-nowrap justify-between gap-5">
          <div className="w-full md:w-1/4 bg-transparent order-2 md:order-1 flex justify-center items-center flex-row md:flex-col gap-1">
            <img src={data.small_img[0]} alt="" className="w-16 h-20" />
            <img src={data.small_img[1]} alt="" className="w-16 h-20" />
            <img src={data.small_img[2]} alt="" className="w-16 h-20" />
            <img src={data.small_img[3]} alt="" className="w-16 h-20" />
            <img src={data.small_img[4]} alt="" className="w-16 h-20" />
          </div>
          <div className="w-full md:w-3/4 order-1 md:order-2 flex justify-center md:justify-center items-center">
            <img src={data.img} alt="" className="w-80" />
          </div>
        </div>

        <div className="w-full md:flex-grow">
          <div className="overflow-y-scroll scroll-smooth flex flex-col gap-y-2 h-[350px] no-scrollbar">
            <p className="text-base md:text-lg w-full">{data.mobileName}</p>
            <p className="font-bold text-base md:text-lg">
              Brand: {data.brand}
            </p>
            <p className="flex justify-start items-center ">
              {" "}
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaStar className="text-yellow-500" />
              <FaRegStarHalfStroke className="text-yellow-500" />
              &nbsp; <span className="text-blue-500">({data.user} users)</span>
            </p>
            <p className="text-orange-500 font-bold text-2xl">
              {data.discount} &nbsp;<b>₹{data.finalprice}</b>
            </p>
            <p className="text-base">
              <b>MRP: </b>₹{data.price}
            </p>

            <div className="pl-2">
              <p className="mb-2">Availabxle offers</p>
              <p className="flex flex-row items-center gap-1 flex-wrap mb-4">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-sm">Bank Offer 5%</b>
                <p className="texts-m">Cashback on Flipkart Axis Bank Card</p>
                <p className="text-sm hover:underline duration-300 text-blue-500">
                  T&C
                </p>
              </p>
              <p className="flex flex-row items-center gap-1 flex-wrap mb-4">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-sm">Partner Offer</b>
                <p className="texts-m">
                  Make a purchase and enjoy a surprise cashback/ coupon that you
                  can redeem later!
                </p>
                <p className="text-sm hover:underline duration-300 text-blue-500">
                  T&C
                </p>
              </p>
              <p className="flex flex-row items-center gap-1 flex-wrap mb-4">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-sm">Special Price</b>
                <p className="text-sm">
                  Get extra ₹3200 off (price inclusive of cashback/coupon)
                </p>
                <p className="text-sm hover:underline duration-300 text-blue-500">
                  Know more
                </p>
              </p>
              <p className="flex flex-row items-center gap-1 flex-wrap mb-4">
                <MdLocalOffer className="text-lg text-blue-500 " />{" "}
                <b className="text-sm">No cost EMI ₹2,933/month</b>
                <p className="text-sm">Standard EMI also available</p>
                <p className="text-sm hover:underline duration-300 text-blue-500">
                  View Plans
                </p>
              </p>
            </div>

            <div className=" flex flex-col gap-2">
              <p className="mt-2 text-green-500">IN STOCK</p>
              <div className="text-sm">
                <div className="pl-4 flex justify-start items-center gap-3">
                  <span className="text-gray-600 text-base">Color: </span>{" "}
                  <p>Black</p>
                  <p className="text-blue-500">Blue</p>
                  <p className="text-green-500">Green</p>
                </div>
              </div>
              <div className="text-sm">
                <div className="pl-4 flex justify-start items-center gap-3">
                  <span className="text-gray-600 text-base">Size: </span>{" "}
                  <p className="p-2 border rounded-md hover:bg-slate-200 duration-300">
                    {data.size[0]}
                  </p>
                  <p className="p-2 border rounded-md hover:bg-slate-200 duration-300">
                    {data.size[1]}{" "}
                  </p>
                  <p className="p-2 border rounded-md hover:bg-slate-200 duration-300">
                    {data.size[2]}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos ad
              reiciendis tenetur labore nulla sequi tempore? Fuga dignissimos
              repellat ea earum ipsa assumenda mollitia quaerat placeat nostrum,
              reiciendis exercitationem tempore pariatur laudantium? Veritatis
              reiciendis at consectetur aperiam, minus quod fuga sed laboriosam
              possimus? Consequuntur quos dolorum explicabo consequatur nesciunt
              quam labore fuga aspernatur illo maiores, repellendus cum.
              Eligendi eaque nostrum, error placeat quasi fuga molestiae. Harum,
              voluptates, deleniti, odio provident architecto quis ipsam esse
              dolorem culpa ipsa consequatur veniam eaque numquam nulla unde!
              Pariatur, enim earum? Dolorum facere sed, laboriosam quas hic
              veritat
            </div>
          </div>
          <div className="flex justify-center md:justify-start items-center gap-10 my-5">
            <button className="px-5 py-2 border rounded-md  w-1/2 md:w-1/4 bg-orange-500 text-white hover:bg-orange-600 font-bold duration-300">
              Add to Cart
            </button>
            <div className="px-5 py-2 border rounded-md w-1/2 md:w-1/4 flex justify-around bg-slate-800 text-white text-lg">
              <button className="px-3">-</button>
              <p>1</p>
              <button className="px-3">+</button>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-lg md:text-2xl font-bold mb-1">About this item</h1>
      <ul className="list-disc pl-5 w-full flex flex-col gap-2">
        <li>
          Display: Large 17.24cm FHD+ 120Hz AdaptiveSync display with Corning
          Gorilla Glass 3 Protection, TÜV Rheinland low blue light, TÜV
          flicker-free, TÜV Circadian Friendly, Wet finger touch display
        </li>
        <li>
          Display: Large 17.24cm FHD+ 120Hz AdaptiveSync display with Corning
          Gorilla Glass 3 Protection, TÜV Rheinland low blue light, TÜV
          flicker-free, TÜV Circadian Friendly, Wet finger touch display
        </li>
        <li>
          Display: Large 17.24cm FHD+ 120Hz AdaptiveSync display with Corning
          Gorilla Glass 3 Protection, TÜV Rheinland low blue light, TÜV
          flicker-free, TÜV Circadian Friendly, Wet finger touch display
        </li>
        <li>
          Display: Large 17.24cm FHD+ 120Hz AdaptiveSync display with Corning
          Gorilla Glass 3 Protection, TÜV Rheinland low blue light, TÜV
          flicker-free, TÜV Circadian Friendly, Wet finger touch display
        </li>
      </ul>
    </div>
  );
}

export default SingleProduct;

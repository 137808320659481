import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function OtpVerify() {
  const [otp, setOtp] = useState(["", "", "", "","",""]);
  const inputRefs = useRef([]);
  const navigate=useNavigate();
//   console.log(inputRefs)

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input field
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        // Move focus to the previous input field
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  const handleSubmit = () => {
    if (otp.every(value => value !== '')) {
      const otpValue = otp.join('');
      console.log('Submitted OTP:', otpValue);
      // Handle OTP submission logic here
      navigate("/new_password")
    } else {
      alert('Please fill all the input boxes.');
    }
  };

  return (
    <div className="section" style={{backgroundColor:"transparent"}}>
      <div className="container mx-auto px-4 h-full my-10 flex justify-center items-center">
        <div className="w-full md:w-[500px]  p-6 shadow-md shadow-slate-300 rounded-lg bg-white">
          <h1 className="text-lg md:text-xl font-bold text-center">Verify</h1>
          <p className="text-center mt-1 text-sm">
            Your code was sent to you via email
          </p>
          <div className="my-5 flex justify-center gap-4">
            {otp.map((_, index) => (
              <input
                key={index}
                type="text"
                value={otp[index]}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength="1"
                ref={(el) => (inputRefs.current[index] = el)}
                className="w-10 h-10 border border-slate-400 outline-none rounded-md text-center"
              />
            ))}
          </div>

          <div className="w-full flex items-center justify-center mb-5">
            <button onClick={handleSubmit} className="border py-2 px-5 rounded-md bg-emerald-800 text-white hover:bg-emerald-900 duration-300">Verify</button>
          </div>
          <div className="flex justify-center items-center text-sm">
            Didn't recive code? &nbsp;<Link to="/forget_password" className="hover:underline duration-300 text-blue-600 text-sm">Request Again</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpVerify;

import React, { useState } from "react";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { MdOutlineVisibility } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import googleLogo from "../assets/google.png";
import signinLogo from "../assets/signin.gif";
import imageToBase64 from "../helper/imageToBase64";
import { checkValidation } from "../validations/checkvalidation";
import { useDispatch } from "react-redux";
import { sigupAction } from "../redux/actions";
function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    profileImage: "",
    c_password: "",
  });
  const [dataError, setDataError] = useState({
    name: "name field is Required",
    email: "email field is Required",
    password: "password field is Required",
    c_password: "confirm password is Required",
  });
  const [errorShow, setErrorShow] = useState(false);
  const navigate = useNavigate();
  const dispatch=useDispatch()

  function handleData(e) {
    const { name, value } = e.target;
    setErrorShow(false);
    setDataError((oldError) => {
      let error = checkValidation(e);
      if (name === "c_password") {
        error = value !== data.password ? "Passwords do not match" : "";
      }
      return { ...oldError, [name]: error };
    });
    setData((old) => {
      return { ...old, [name]: value.trim() };
    });
  }

  function comparePasswords(password, confirmPassword) {
    return password === confirmPassword;
  }

  const handleDataImg = async (e) => {
    const file = e.target.files[0];
    // console.log(file);
    if (file) {
      try {
        const profile = await imageToBase64(file);
        console.log(profile);
        setData((prev) => {
          return { ...prev, profileImage: profile };
        });
        // console.log(profile);
      } catch (error) {
        console.error(error);
      }
    } // console.log(profile);
  };

  function submitData(event) {
    event.preventDefault();
    if (
      !Object.keys(dataError).find((x) => dataError[x] && dataError[x] !== "")
    ) {
      if (!comparePasswords(data.password, data.c_password)) {
        setDataError((oldError) => ({
          ...oldError,
          c_password: "Passwords do not match",
        }));
        setErrorShow(true);
      } else {
        // console.log(data);
        const finalData = {
          name:data.name,
          email:data.email,
          password:data.password,
          profileImage:data.profileImage,
          authMethod:"email"
        }
        console.log(finalData);
        dispatch(sigupAction(finalData,(response)=>{
            console.log(response, "response----------------------------------------");
        }))
      }
    } else {
      setErrorShow(true);
    }
  }

  return (
    <section className="section">
      <div className="container mx-auto px-4 h-full flex justify-center items-center">
        <div className="w-full md:w-1/2 p-6 flex justify-center items-center flex-col">
          <div className="w-20 h-20 mx-auto relative overflow-hidden rounded-full">
            <div>
              <img
                src={data.profileImage || signinLogo}
                alt="login logo"
                className="object-cover "
              />
            </div>
            <form action="">
              <label>
                <div className="w-full bg-slate-200 py-3  absolute top-10 text-center text-xs opacity-60 cursor-pointer">
                  upload pic
                </div>
                <input
                  type="file"
                  className="hidden"
                  name="profileImage"
                  onChange={handleDataImg}
                />
              </label>
            </form>
          </div>

          <form
            action=""
            className="mt-3 w-full md:max-w-sm"
            onSubmit={submitData}
          >
            {/* {UserName } */}
            <div className="flex flex-col mb-4">
              <label htmlFor="user" className="text-sm">
                Username <sup className="text-red-500">*</sup>
              </label>
              <input
                type="text"
                placeholder="Username.."
                id="user"
                name="name"
                onChange={handleData}
                className="bg-slate-200 p-2 outline-none rounded-md text-sm"
              />
              {errorShow ? (
                <p className="text-[13px] mt-1 text-red-500">
                  {dataError.name}
                </p>
              ) : (
                ""
              )}
            </div>

            {/* {Email } */}
            <div className="flex flex-col mb-3">
              <label htmlFor="email" className="text-sm">
                Email <sup className="text-red-500">*</sup>
              </label>
              <input
                type="email"
                placeholder="Enter your email address"
                id="email"
                name="email"
                onChange={handleData}
                className="bg-slate-200 p-2 outline-none rounded-md text-sm"
              />
              {errorShow ? (
                <p className="text-[13px] mt-1 text-red-500">
                  {dataError.email}
                </p>
              ) : (
                ""
              )}
            </div>

            {/* {Password} */}
            <div className="flex flex-col mb-3">
              <label htmlFor="password" className="text-sm">
                Password <sup className="text-red-500">*</sup>
              </label>
              <div className="w-full bg-slate-200 flex rounded-md overflow-hidden">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Your Password"
                  id="password"
                  name="password"
                  onChange={handleData}
                  className="bg-slate-200 p-2 outline-none rounded-md w-full text-sm"
                />
                <p
                  className="cursor-pointer w-8 h-9 flex items-center"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineVisibility className="text-lg" />
                  ) : (
                    <MdOutlineVisibilityOff className="text-lg" />
                  )}
                </p>
              </div>
              {errorShow?(<p className="text-[13px] mt-1 text-red-500">{dataError.password}</p>):""}
            </div>

            {/* {confirm Password} */}
            <div className="flex flex-col mb-3">
              <label htmlFor="c_password" className="text-sm">
                Confirm password<sup className="text-red-500">*</sup>
              </label>
              <div className="w-full bg-slate-200 flex rounded-md overflow-hidden">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  id="c_password"
                  name="c_password"
                  onChange={handleData}
                  className="bg-slate-200 p-2 outline-none rounded-md w-full text-sm"
                />
                <p
                  className="cursor-pointer w-8 h-9 flex items-center"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <MdOutlineVisibility className="text-lg" />
                  ) : (
                    <MdOutlineVisibilityOff className="text-lg" />
                  )}
                </p>
              </div>
              {errorShow?(<p className="text-[13px] mt-1 text-red-500">{dataError.c_password}</p>):""}
            </div>

            {/* button login  */}
            <div className="w-full border rounded-md mb-3 overflow-hidden">
              <button
                // onClick={SubmiData}
                className="w-full py-[7px] text-sm text-center bg-emerald-800 text-white hover:bg-emerald-900 duration-300"
              >
                Sign Up
              </button>
            </div>
          </form>

          <div className="w-full md:max-w-sm">
            <div className="mb-4 flex text-center items-center gap-2">
              <div className="h-[1px] flex-1 bg-slate-300"></div>
              <span className=" whitespace-nowrap text-sm text-slate-500">
                or login with
              </span>
              <div className="h-[1px] flex-1 bg-slate-300"></div>
            </div>

            <div className="w-full border rounded-md mb-3 overflow-hidden py-[7px] flex justify-center items-center gap-2">
              <div className="w-4 h-4">
                <img
                  className="w-full h-full"
                  src={googleLogo}
                  alt="googleLogo"
                />
              </div>
              <p className="text-sm">Sign up with Google</p>
            </div>

            <div className="text-center text-sm">
              Already have an account ? &nbsp;{" "}
              <Link
                to="/login"
                className="hover:underline duration-300 text-blue-600"
              >
                Login
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden md:flex w-1/2  items-center">
          <img
            src="https://img.freepik.com/free-vector/online-shopping-concept-landing-page_52683-9637.jpg?t=st=1722575755~exp=1722579355~hmac=8743bcaaa07242e749890774800ce369fad63099b8f8b501c6ea4364dcd69e39&w=740"
            alt="SignUp Image"
            className="w-full lg:max-w-md object-cover"
          />
        </div>
      </div>
    </section>
  );
}

export default Signup;

import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";
import Productlist from "../components/Productlist";
import furnishing from "../assets/furnishing.json"


function  Home_furnishing() {
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg",
  ];
  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white mt-7">
        <div>Bookshelves</div>
        <div>Carpets</div>
        <div>Dressing Table</div>
        <div>Lounge Chaires</div>
        <div>Nesting Tables</div>
        <div>Outdoor Tables</div>
        <div>Rocking</div>
        <div>shoe Rock</div>
        <div>Side Tables</div>
        <div>Stools</div>
        <div>Wall Shelves</div>
      </div>
      <div className="p-2 py-4 md:p-6 my-5">           
            <div className="w-full bg-green-500">
              <Productlist product={furnishing}/>
            </div>
      </div>
    </div>
  );
}

export default  Home_furnishing;



import React from 'react'
import { Link } from 'react-router-dom';

function Productlist3({product}) {
  return (
    <>
        <div className="w-full flex justify-evenly md:justify-between items-center gap-5 flex-wrap">
        {product.map((item, index) => {
          return (
            <Link
              to={`/product/${item.brand}/${item.id}`}
              className="bg-transparent w-44 flex items-center justify-center rounded-md h-48"
            >
              <img src={item} alt="" className='w-full h-full'/>
            </Link>
          );
        })}
      </div>
    </>
  )
}

export default Productlist3
import React from "react";

function HomeAppliances({ appliances }) {
  // console.log(Appliances)
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 md:flex md:flex-wrap md:justify-evenly md:gap-7">
      {appliances.map((item, index) => (
        <div key={index} className="flex flex-col items-center md:w-44">
          <div>
            <img src={item.img} alt={item.name} className="w-full h-full"/>
          </div>
          <p className="text-justify text-sm mt-1 w-1/2 md:w-full">{item.name}</p>
          <p className="text-center text-emerald-800 text-lg mt-1">{item.price}</p>
        </div>
      ))}
    </div>
  );
}

export default HomeAppliances;

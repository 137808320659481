import React, { useState } from "react";
import { Link } from "react-router-dom";
import googleLogo from "../assets/google.png";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {

    const  navigate=useNavigate();

    function SubmitOtpData(e){
        e.preventDefault();
        navigate("/otp_verify")
    }
  return (
    <section className="section">
      <div className="container mx-auto px-4 h-full flex justify-center items-center">
        <div className="w-full md:w-1/2 p-6 md:pl-20">
          <form action="" className="mt-5 w-full md:max-w-sm">
            {/* {Email } */}
            <div className="flex flex-col mb-4">
              <label htmlFor="email" className="text-sm">
                Email <sup className="text-red-500">*</sup>
              </label>
              <input
                type="email"
                placeholder="Enter your email address"
                id="email"
                className="bg-slate-200 p-2 outline-none rounded-md text-sm"
              />
            </div>

            {/* button login  */}
            <div className="w-full border rounded-md mb-4 overflow-hidden">
              <button onClick={SubmitOtpData} className="w-full py-[7px] text-sm text-center bg-emerald-800 text-white hover:bg-emerald-900 duration-300">
                send OTP
              </button>
            </div>
          </form>

          <div className="w-full md:max-w-sm">

            <div className="mb-4 flex text-center items-center gap-2">
              <div className="h-[1px] flex-1 bg-slate-300"></div>
              <span className=" whitespace-nowrap text-sm text-slate-500">or login with</span>
              <div className="h-[1px] flex-1 bg-slate-300"></div>
            </div>

            <div className="w-full border rounded-md mb-4 overflow-hidden py-[7px] flex justify-center items-center gap-2">
              <div className="w-4 h-4">
                <img
                  className="w-full h-full"
                  src={googleLogo}
                  alt="googleLogo"
                />
              </div>
              <p className="text-sm">Sign up with Google</p>
            </div>

            <div className="text-center text-sm">
                Don't have an account ? &nbsp; <Link to="/sign_up" className="hover:underline duration-300 text-blue-600">Sign up</Link>
            </div>
          </div>
        </div>

        <div className="hidden md:flex w-1/2  items-center">
          <img
            src="https://img.freepik.com/premium-photo/guardian-digital-realm-mans-vigilance-login-gate_1134661-21588.jpg?w=740"
            alt="login Image"
            className="w-full lg:max-w-md object-cover"
          />
        </div>
      </div>
    </section>
  );
}

export default ForgetPassword;

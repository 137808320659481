import React from "react";
import { Link } from "react-router-dom";

function Productlist2({ product }) {
  return (
    <div className="w-full flex justify-center md:justify-between gap-x-5 gap-y-32 flex-wrap p-4 mt-32">
      {product.map((item, index) => (
        <Link
          key={index} // Added a key for each Link component
          to={`/product/${item.brand}/${item.id}`}
          className="w-72 pt-32 relative flex flex-col justify-center items-center gap-y-3 p-4 border bg-lime-200 rounded-3xl"
        >
          <div className="w-full flex justify-center items-center absolute -top-24 h-52">
            <img src={item.img} alt="" className="h-56 duration-200 hover:scale-110" />
          </div>
          <div className="p-2 flex flex-col gap-2">
            <p className="w-full font-semibold">{item.productName}</p>
            <p className="w-full text-sm">{item.battery}</p>
            <h2 className="text-sm bg-white p-1 flex gap-5"><del>₹ {item.price}</del> <b> ₹ {item.finalprice}</b></h2>
            <h2 className="text-sm"><sup>* including band & coupon offer</sup></h2>

          </div>
        </Link>
      ))}
    </div>
  );
}

export default Productlist2;

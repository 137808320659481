import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";
import Productlist from "../components/Productlist";
import electronics from "../assets/electronics.json";
import MobileBannerCmp from "../components/MobileBannerCmp";
import Productlist2 from "../components/Productlist2";

function Electronics() {
  // const banner = [
  //   "/assets/mobile/mobileBanner1.png",
  //   "/assets/mobile/mobileBanner2.webp",
  //   "/assets/mobile/mobileBanner3.webp",
  //   "/assets/mobile/mobileBanner4.webp",
  //   "/assets/mobile/mobileBanner5.webp",
  // ];
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg"
  ];
  const electronicBanner = {
    img: "/assets/electronics/headphone2.png",
    modelName: "Boult Z40 with Zen ENC Mic",
    mobileName: "Boult",
    mobile: "bolt",
  };
  const electronicBanner2 = {
    img: "/assets/electronics/fan5.png",
    modelName: "Celling Z40 with Zen ENC Mic",
    mobileName: "Boult",
    mobile: "bolt",
  };

  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white my-7">
        <div>mobile</div>
        <div>laptop</div>
        <div>headphone</div>
        <div>earbuds</div>
        <div>celling fan</div>
        <div>Charger</div>
        <div>Mouse</div>
        <div>speaker</div>
        <div>Honor</div>
        <div>Google</div>
      </div>
      <div className="p-2 py-4 md:p-6">
        <Productlist product={electronics} />
        <MobileBannerCmp BannerCmp={electronicBanner} />
        <Productlist2 product={electronics} />
        <MobileBannerCmp BannerCmp={electronicBanner2} />
        <Productlist2 product={electronics} />
      </div>
    </div>
  );
}

export default Electronics;

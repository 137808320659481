import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";
import Productlist from "../components/Productlist";
import Productlist3 from "../components/Productlist3";
import books from "../assets/books.json"

function Books() {
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg",
  ];
  const book1 = [
    "/assets/books/book1.png",
    "/assets/books/book2.png",
    "/assets/books/book3.png",
    "/assets/books/book4.png",
    "/assets/books/book5.png",
    "/assets/books/book6.png",
  ];
  const bookchild = [
    "/assets/books/bookchild1.png",
    "/assets/books/bookchild2.png",
    "/assets/books/bookchild3.png",
    "/assets/books/bookchild4.png",
    "/assets/books/bookchild5.png",
  ];
  const subBooks = [
    "/assets/books/subBooks1.png",
    "/assets/books/subBooks2.png",
    "/assets/books/subBooks3.png",
    "/assets/books/subBooks4.png",
    "/assets/books/subBooks5.png",
    "/assets/books/subBooks6.png",
  ];
  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white mt-7">
        <div>Action & Adventure</div>
        <div>Business & Economics</div>
        <div>Children's Books</div>
        <div>Comics & Mangas</div>
        <div>Engineering</div>
        <div>Exam Preparation</div>
        <div>History</div>
        <div>More</div>
      </div>
      <div className="p-2 py-4 md:p-6 my-7">
        <div className=" mb-5">
          <Productlist3 product={bookchild} />
        </div>
        <div className="w-full mb-5 p flex gap-5 justify-between flex-wrap md:flex-nowrap items-center">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/books/bookBanner1.png"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="w-full md:w-1/2">
            <img
              src="/assets/books/bookBanner2.png"
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="mb-5">
          <Productlist3 product={book1} />
        </div>
        <div className="mb-5">
          <Productlist product={books}/>
        </div>
      </div>
    </div>
  );
}

export default Books;

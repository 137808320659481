import React from "react";
import BannerSlider from "../components/BannerSlider";
import CategorySlider from "../components/CategorySlider";
import Productlist from "../components/Productlist";
import mobile from "../assets/mobile.json";
import mobileRealme from "../assets/mobileRealme.json";
import mobileSamsung from "../assets/mobileSamsung.json";
import mobileRedmi from "../assets/mobileRedmi.json";
import MobileBannerCmp from "../components/MobileBannerCmp";
import Productlist2 from "../components/Productlist2";

function Mobile() {
  // const banner = [
  //   "/assets/mobile/mobileBanner2.webp",
  //   "/assets/mobile/mobileBanner3.webp",
  //   "/assets/mobile/mobileBanner4.webp",
  //   "/assets/mobile/mobileBanner5.webp",
  // ];
  const banner = [
    "https://www.etcspl.com/wp-content/uploads/2017/11/e-commerce-banner.jpg",
    "https://4.bp.blogspot.com/-j08zU37hpt4/W5aaDndpsWI/AAAAAAAAFoc/tq-c11-V1sgMDyFd5cB3Z6jsO2UICZiQgCK4BGAYYCw/s1600/CL-Banner.jpg",
    "https://static.vecteezy.com/system/resources/previews/004/707/493/non_2x/online-shopping-on-phone-buy-sell-business-digital-web-banner-application-money-advertising-payment-ecommerce-illustration-search-vector.jpg",
    "https://www.jdmedia.co.za/images/carousel/Ecommerce-Banner-1920.jpg",
    "https://nimbuspost.com/wp-content/uploads/2023/02/Top-5-Tips-to-Make-eCommerce-Banners-for-Your-Online-Store.jpg"
  ];

  const mobileBanner={img:"/assets/mobile/poco.png",modelName:"NARZO 70 x 5G",mobileName:"Narzo",mobile:"realme"}
  const mobileBanner2={img:"/assets/mobile/poco.png",modelName:"Samsung Galaxy M35 5G",mobileName:"Galaxy",mobile:"Samsung"}
  const mobileBanner3={img:"/assets/mobile/poco.png",modelName:"Redmi Note 13 Pro 5G",mobileName:"Redmi",mobile:"Redmi"}
  return (
    <div className="w-full">
      <main className="">
        <CategorySlider />
      </main>
      <BannerSlider banner={banner} />
      <div className="w-full p-4 flex justify-stretch xl:justify-between items-center flex-wrap gap-6 font-bold bg-slate-800 text-white my-7">
        <div>Redmi</div>
        <div>OnePlus</div>
        <div>Realme</div>
        <div>Poco</div>
        <div>Apple</div>
        <div>Nokia</div>
        <div>Oppo</div>
        <div>Vivo</div>
        <div>Honor</div>
        <div>Google</div>
      </div>

      <div className="p-2 py-4 md:p-6">
        <Productlist product={mobile} />
        <MobileBannerCmp BannerCmp={mobileBanner} />
        <Productlist2 product={mobileRealme}/>
        <MobileBannerCmp BannerCmp={mobileBanner2} />
        <Productlist2 product={mobileSamsung}/>
        <MobileBannerCmp BannerCmp={mobileBanner3} />
        <Productlist2 product={mobileRedmi}/>
      </div>
    </div>
  );
}

export default Mobile;
